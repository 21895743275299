var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { State } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { TemplateService } from "~/services/manage-service/template.service";
import MessageTemplate from "~/components/templete-manage/templete-config/message-template.vue";
import EmailTemplate from "~/components/templete-manage/templete-config/email-template.vue";
import CallAccTemplate from "~/components/templete-manage/templete-config/call-acc-template.vue";
import LetterTemplate from "~/components/templete-manage/templete-config/letter-template.vue";
//@Auth(1742)
var TemplateConfig = /** @class */ (function (_super) {
    __extends(TemplateConfig, _super);
    function TemplateConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.selectionList = [];
        _this.templateModel = {
            principalId: "",
            templateName: "",
            type: "",
            isEnabled: "",
        };
        _this.templateDataSet = [];
        _this.dialog = {
            createTemplate: false,
            modifyTemplate: false,
        };
        _this.radio = "MessageTemplate"; // 默认新建模版
        _this.modifyTemp = ""; // 默认新建模版
        _this.updateData = {};
        _this.templateData = {
            SMS: "MessageTemplate",
            EMAIL: "EmailTemplate",
            TEL: "CallAccTemplate",
            LETTER: "LetterTemplate",
        };
        return _this;
    }
    TemplateConfig.prototype.mounted = function () {
        this.refreshData();
    };
    TemplateConfig.prototype.refreshData = function () {
        var _this = this;
        this.templateService.getAllTemplates(this.templateModel, this.pageService, this.loading).subscribe(function (data) {
            _this.templateDataSet = data;
        });
    };
    /**
     * 打开新增模板弹框
     */
    TemplateConfig.prototype.createTemplateClick = function () {
        this.dialog.createTemplate = true;
    };
    /**
     * 修改模板
     */
    TemplateConfig.prototype.updateClick = function (scope) {
        this.updateData = scope.row;
        this.modifyTemp = this.templateData[scope.row.type];
        this.dialog.modifyTemplate = true;
    };
    /**
     * 删除模板
     */
    TemplateConfig.prototype.deleteClick = function (scope) {
        var _this = this;
        this.$confirm("您确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            _this.templateService.deleteTemplateById(scope.row.id).subscribe(function (data) {
                _this.$message({
                    type: "success",
                    message: "删除成功!",
                });
                // 更新数据信息
                _this.refreshData();
            });
        });
    };
    /**
     * 重置
     */
    TemplateConfig.prototype.reset = function () {
        this.radio = "MessageTemplate";
        this.modifyTemp = "MessageTemplate";
    };
    __decorate([
        Dependencies(TemplateService)
    ], TemplateConfig.prototype, "templateService", void 0);
    __decorate([
        Dependencies(PageService)
    ], TemplateConfig.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], TemplateConfig.prototype, "sortService", void 0);
    __decorate([
        State
    ], TemplateConfig.prototype, "principalList", void 0);
    TemplateConfig = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                MessageTemplate: MessageTemplate,
                EmailTemplate: EmailTemplate,
                CallAccTemplate: CallAccTemplate,
                LetterTemplate: LetterTemplate,
            },
        })
    ], TemplateConfig);
    return TemplateConfig;
}(Vue));
export default TemplateConfig;
