var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import { Watch, Prop, Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { TemplateService } from "~/services/manage-service/template.service";
import { quillEditor } from "vue-quill-editor";
import { templateVarType } from "~/config/enum.config";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var LetterTemplete = /** @class */ (function (_super) {
    __extends(LetterTemplete, _super);
    function LetterTemplete() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.letterModel = {
            principalId: "",
            principalName: "",
            isDefault: false,
            templateName: "",
            isEnabled: "YES",
            content: "",
            type: "LETTER"
        };
        _this.defaultFlag = false;
        _this.isModify = false;
        _this.selectedKey = "";
        _this.KeyResource = templateVarType;
        _this.editorOption = {
            placeholder: "请输入文本",
            // 配置各个模块
            modules: {
                // 配置工具栏的内容
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [
                        {
                            list: "ordered"
                        },
                        {
                            list: "bullet"
                        },
                        {
                            list: "check"
                        }
                    ],
                    [
                        {
                            header: [1, 2, 3, 4, 5, 6, false]
                        }
                    ],
                    [
                        {
                            color: []
                        },
                        {
                            background: []
                        }
                    ],
                    [
                        {
                            font: []
                        }
                    ],
                    [
                        {
                            align: []
                        }
                    ],
                    ["clean"]
                ]
            }
        };
        _this.rules = {
            principalId: [
                {
                    required: true,
                    message: "请选择委托方",
                    trigger: "blur"
                }
            ],
            templateName: [
                {
                    required: true,
                    message: "请输入模板名称",
                    trigger: "blur"
                },
                {
                    message: "模板名称只能是汉字或字母",
                    trigger: "blur",
                    pattern: /^([\u4e00-\u9fa5]+|([a-zA-Z]+\s?)+)$/
                },
                {
                    min: 2,
                    max: 10,
                    message: "长度在 2 到 10 个字符",
                    trigger: "blur"
                }
            ],
            content: [
                {
                    required: true,
                    message: "请输入模板内容",
                    trigger: "blur"
                },
                {
                    min: 0,
                    max: 4000,
                    message: "不超过4000个字符",
                    trigger: "blur"
                }
            ]
        };
        return _this;
    }
    LetterTemplete.prototype.close = function () {
        var letterFrom = this.$refs["letter-form"];
        letterFrom.resetFields();
        this.letterModel.isDefault = false;
        this.selectedKey = "";
    };
    LetterTemplete.prototype.success = function () { };
    LetterTemplete.prototype.updateDataChange = function (val) {
        if (!val)
            return;
        this.isModify = true;
        this.letterModel = Object.assign({}, this.updateData);
        this.letterModel.isDefault =
            this.letterModel.isDefault === "YES" ? true : false;
        this.formatContent(1);
    };
    /**
     * 提交
     */
    LetterTemplete.prototype.submit = function () {
        var _this = this;
        if (this.letterModel.content === "") {
            this.$message({
                type: "info",
                message: "模板内容不能为空!"
            });
            return;
        }
        this.formatContent(0);
        var letterFrom = this.$refs["letter-form"];
        letterFrom.validate(function (valid) {
            if (!valid) {
                return;
            }
            if (_this.letterModel.isDefault && _this.letterModel.isEnabled === "NO") {
                _this.$message({
                    type: "info",
                    message: "默认模板不可设为停用!"
                });
                return;
            }
            if (_this.isModify) {
                _this.modifyTemplate();
            }
            else {
                _this.addTemplate();
            }
        });
    };
    /**
     * 插入选中的参数到富文本光标所在之处
     */
    LetterTemplete.prototype.insertToTextArea = function () {
        if (this.selectedKey === "") {
            return;
        }
        var editor = this.$refs["editor"];
        var quill = editor.quill;
        quill.focus();
        var selection = quill.getSelection();
        var insertKey = "[" + this.selectedKey + "]";
        if (selection.length > 0) {
            // 如果是选中模式需要先删除原有数据
            quill.deleteText(selection.index, selection.length);
        }
        quill.insertText(selection.index, insertKey);
        quill.setSelection(selection.index + insertKey.length, 0);
    };
    /*
       * 格式化富文本内容
       * mode: 0 代表将富文本内容中的参数转换为字段
       *       1 代表将富文本中的字段转为可阅读参数名称
       */
    LetterTemplete.prototype.formatContent = function (mode) {
        var message = "";
        var regex = "";
        if (mode === 0) {
            message = this.letterModel.content;
            this.KeyResource.map(function (obj) {
                // 0 替换为Value
                regex = new RegExp("\\[\\" + obj.name + "?\\]", "g");
                message = message.replace(regex, obj.value);
            });
            // 先替换\t
            regex = new RegExp("\\t{1}", "g");
            message = message.replace(regex, "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
            // 然后替换\s [空格]
            regex = new RegExp("\\s{2}?", "g");
            message = message.replace(regex, "&nbsp;&nbsp;");
            this.letterModel.content = message;
        }
        else {
            message = this.letterModel.content;
            // 1 替换为name
            this.KeyResource.map(function (obj) {
                regex = new RegExp("\\" + obj.value + "?", "g");
                message = message.replace(regex, "[" + obj.name + "]");
            });
            this.letterModel.content = message;
        }
    };
    /**
     * 新增模板
     */
    LetterTemplete.prototype.addTemplate = function () {
        var _this = this;
        var letterModel = Object.assign({}, this.letterModel);
        letterModel.isDefault = this.letterModel.isDefault ? "YES" : "NO";
        letterModel.principalName =
            this.principalList.find(function (v) { return v.id === letterModel.principalId; })
                .name || "";
        this.loading.state = true;
        this.templateService.createTemplate(letterModel, this.loading).subscribe(function (data) {
            _this.$message({
                type: "success",
                message: "新增模版成功!"
            });
            _this.success();
            _this.close();
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    /**
     * 修改模板
     */
    LetterTemplete.prototype.modifyTemplate = function () {
        var _this = this;
        var letterModel = Object.assign({}, this.letterModel);
        letterModel.isDefault = this.letterModel.isDefault ? "YES" : "NO";
        letterModel.principalName =
            this.principalList.find(function (v) { return v.id === letterModel.principalId; })
                .name || "";
        this.loading.state = true;
        this.templateService.updateTemplate(letterModel, this.loading).subscribe(function (data) {
            _this.$message({
                type: "success",
                message: "修改模版成功!"
            });
            _this.$emit("success");
            _this.close();
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(TemplateService)
    ], LetterTemplete.prototype, "templateService", void 0);
    __decorate([
        State
    ], LetterTemplete.prototype, "principalList", void 0);
    __decorate([
        Emit("close")
    ], LetterTemplete.prototype, "close", null);
    __decorate([
        Emit("success")
    ], LetterTemplete.prototype, "success", null);
    __decorate([
        Prop()
    ], LetterTemplete.prototype, "updateData", void 0);
    __decorate([
        Watch("updateData", { immediate: true })
    ], LetterTemplete.prototype, "updateDataChange", null);
    LetterTemplete = __decorate([
        Component({
            components: {
                quillEditor: quillEditor
            }
        })
    ], LetterTemplete);
    return LetterTemplete;
}(Vue));
export default LetterTemplete;
